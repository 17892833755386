import { Box, Link, Text, Image, chakra, Heading, AccordionButton } from "@chakra-ui/react";

/* declare custom components to keep the code DRY */

//footer styles
export const CustomLink = chakra(Link, {
  baseStyle: {
    fontSize: "12px",
    fontFamily: "sequel-sans-book-head",
  },
});

export const ListHeader = chakra(Text, {
  baseStyle: {
    fontSize: "13px",
    fontFamily: "sequel-sans-medium-body",
    marginBottom: 2,
  },
});

export const CustomText = chakra(Text, {
  baseStyle: {
    fontSize: "10px",
    fontFamily: "sequel-sans-light-head",
  },
});

export const MobileMenuListHeader = chakra(Text, {
  baseStyle: {
    fontSize: "18px",
    fontFamily: "sequel-sans-medium-head",
    marginBottom: 2,
  },
});

export const CustomBox = chakra(Box, {
  baseStyle: {
    flex: 1,
    textAlign: "left",
  },
});

export const CustomAccordionButton = chakra(AccordionButton, {
  baseStyle: {
    borderTop: "1px solid #000000",
  },
});

//home page rectangles custom styles
export const CustomHeading = chakra(Heading, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "sequel-sans-semi-bold-disp",
  },
});

export const RectangleImage = chakra(Image, {
  baseStyle: {
    display: "block",
    mx: "auto",
  },
});

export const BoxWithBgColor = chakra(Box, {
  baseStyle: {
    bg: "#F8F8F8",
  },
});

//languages page
export const CountryLink = chakra(Link, {
  baseStyle: {
    fontSize: "14px",
    color: "#3E7EFD",
    fontFamily: "sequel-sans-medium-head",
  },
});

export const PlanetText = chakra(Text, {
  baseStyle: {
    fontSize: "16px",
    color: "#777777",
    fontFamily: "sequel-sans-book-head",
    _hover: {
      color: "#000000",
      cursor: "pointer",
    },
    pl: 8,
    pt: 4,
  },
});

export const CountryHeading = chakra(Heading, {
  baseStyle: {
    pb: 1,
    pl: [14, 20, 12, 60],
    fontSize: [20, 24, 28],
    fontFamily: "sequel-sans-medium-head",
  },
});

export const EarthImage = chakra(Image, {
  baseStyle: {
    boxSize: ["1.55rem", "1.6rem"],
    ml: "12px",
    mb: 1,
  },
});
