import { Text, Box, Link, HStack } from "@chakra-ui/react";
import capsule from "../../assets/capsule.svg";
import { Link as ReactLink } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { CustomHeading, RectangleImage, BoxWithBgColor } from "../CustomComponents/CustomComponents";

const HomeRectangles = () => {
  return (
    <Box>
      <BoxWithBgColor mt={["-30px", -6, 10, 40, 10, 20]} mb={2}>
        <CustomHeading fontSize={[20, 28]} pt={12}>
          Capsule
          <Text as="sup" mt={2} fontFamily="sequel-sans-semi-bold-disp">
            s
          </Text>
        </CustomHeading>
        <CustomHeading fontSize={[32, 50]}>A private privacy.</CustomHeading>
        <Box>
          <Link
            as={ReactLink}
            color="#3E7EFD"
            fontWeight={500}
            display="flex"
            alignItems="center"
            justifyContent="center"
            to={""}
            fontFamily="sequel-sans-medium-head"
            _hover={{
              textDecoration: "none",
              cursor: "pointer",
            }}
            pt={[2]}
            mb={[-4, 8]}
          >
            <HStack spacing="-2px">
              <Text>Learn more</Text>
              <ChevronRightIcon boxSize={6} pos="relative" top={["1.3px"]} />
            </HStack>
          </Link>
        </Box>
        <RectangleImage src={capsule} alt="capsule" h={[450, 500]} w={[400, 600]} />
      </BoxWithBgColor>

      <BoxWithBgColor my={2} display="none">
        <CustomHeading pt={6}>Kickstarter</CustomHeading>
        <RectangleImage src={capsule} alt="capsule" h={[450, 500]} w={[400, 600]} />
      </BoxWithBgColor>
    </Box>
  );
};

export default HomeRectangles;
