import { Link as ReactLink } from "react-router-dom";
import { parcksCrew } from "../../constants/constants";
import parcksGrayLogo from "./../../assets/gray_logo.png";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { Box, Image, SimpleGrid, Link, Flex, VStack, Text } from "@chakra-ui/react";
import { useNotificationBarVisibility } from "../../context/AutoCountryDetectionBarContext";

const ParcksCrew = () => {
  //language selection bar visibility
  const isVisible = useNotificationBarVisibility();
  return (
    <>
      <BreadCrumb activeLink />
      <Box bg="#FFFFFF" h={["780px", "800px", "680px"]} w="full" mt={20}>
        <Flex alignItems="center" justifyContent="center">
          <Image
            src={parcksGrayLogo}
            alt="Parcks Gray Logo"
            mt={isVisible ? [80, 80, 80, 64, 64, "300px"] : [60, 60, 60, 60, 60, "250px"]}
            h={["246px"]}
            w={["246px"]}
          />
        </Flex>
      </Box>
      <Flex alignItems="center" justifyContent="center">
        <SimpleGrid columns={[1, 1, 2, 2, 2, 2]} pt={4} pb={32} spacing={2}>
          {parcksCrew.map((p, i) => {
            return (
              <Link
                key={i}
                to={`${p.crewMemberId}`}
                as={ReactLink}
                _hover={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <Flex
                  w={[340, 510, 345, 470, 510, 500]}
                  h={[420, 415, 400, 400]}
                  bg="#FAFAFA"
                  borderRadius={15}
                  alignItems="center"
                  justifyContent="center"
                >
                  <VStack spacing={0}>
                    <Text fontFamily="sequel-sans-semi-bold-head" color="#1C1D1F" pt={64}>
                      {p.fullName}
                    </Text>
                    <Text fontFamily="sequel-sans-roman-head" color="#1C1D1F">
                      {p.title}
                    </Text>
                    <Text fontFamily="sequel-sans-light-head" color="#1C1D1F">
                      {p.roleAbbreviation === "CPO" ? p.role : p.roleAbbreviation}
                    </Text>
                  </VStack>
                </Flex>
              </Link>
            );
          })}
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default ParcksCrew;
