import { Link as ReactLink } from "react-router-dom";
import {
  useCountry,
  useNotificationBarVisibility,
  useNotificationBarClose,
} from "../../context/AutoCountryDetectionBarContext";
import { BsFillArrowRightCircleFill, BsCheck2, BsChevronDown } from "react-icons/bs";
import { Box, Text, Icon, Link, HStack, Button, Collapse, CloseButton, useDisclosure } from "@chakra-ui/react";

const LanguageAlert = () => {
  const country = useCountry();
  const isVisible = useNotificationBarVisibility();
  const onClose = useNotificationBarClose();

  //applies a slide down transition to the language selection drop down
  const { isOpen, onToggle } = useDisclosure();

  return (
    isVisible && (
      <>
        <Box
          bg="#F9F9F9"
          h={["200px", "200px", "72px", "72px", "72px", "72px"]}
          width="full"
          alignItems="center"
          justifyContent="center"
          display={{ base: "none", lg: "flex" }}
          position="fixed"
          top={-2}
          zIndex={99999}
        >
          <Box pr={[null, null, 6, 60, 60, 60]}>
            <Text
              fontFamily="sequel-sans-book-head"
              fontSize={[12, 12, 12, 14, 14, 14]}
              maxW={["1xl", "2xl", "5xl", "2xl", "2xl", "2xl"]}
              pos="fixed"
              top={3}
              right={[0, 0, 600, 600, 600, 900]}
              px={[0, 0, 0, 10, 32, 32]}
              ml={[0, 0, 16, 0, 0, 0]}
              color="#1C1D1F"
            >
              Please confirm the country or choose the country relevant to display the right content.
            </Text>
          </Box>

          <Box
            w="268px"
            h="45px"
            bg="#F9F9F9"
            mt={4}
            mr={10}
            onClick={onToggle}
            _hover={{ cursor: "pointer" }}
            pos="fixed"
            right={[null, null, null, 260, 400, 680]}
          >
            <Box>
              <HStack mt={2}>
                <Icon as={BsCheck2} color="#3E7EFD" boxSize={5} />
                <Text pl={2} color="#3E7EFD" fontFamily="sequel-sans-book-head" fontSize={14} textTransform="uppercase">
                  {country}
                </Text>
                <Icon
                  as={BsChevronDown}
                  color="#7A7A7A"
                  boxSize={4}
                  pos="fixed"
                  right={[null, null, null, 320, 460, 738]}
                />
              </HStack>
              <Collapse in={isOpen} animateOpacity transition={{ enter: { duration: 1 }, exit: { duration: 1 } }}>
                {country === "Canada" && (
                  <>
                    <Box
                      as="button"
                      _hover={{
                        bgColor: "#3E7EFD",
                        cursor: "pointer",
                      }}
                      w={"268px"}
                      h={"40px"}
                      bg="#F9F9F9"
                      borderBottomColor="#D4D4D4"
                      borderTopColor="#D4D4D4"
                      borderBottomWidth="1px"
                      borderTopWidth="1px"
                      mt={2}
                    >
                      <Text mr={20} fontSize={14} fontFamily="sequel-sans-book-head">
                        CANADA (English)
                      </Text>
                    </Box>
                    <Box
                      as="button"
                      _hover={{
                        bgColor: "#3E7EFD",
                        cursor: "pointer",
                      }}
                      w={"268px"}
                      h={"40px"}
                      bg="#F9F9F9"
                      borderBottomColor="#D4D4D4"
                      borderBottomWidth="1px"
                    >
                      <Text mr={16} pr={2} fontSize={14} fontFamily="sequel-sans-book-head">
                        CANADA (Français)
                      </Text>
                    </Box>
                  </>
                )}

                {country === "Puerto Rico" && (
                  <>
                    <Box
                      as="button"
                      _hover={{
                        bgColor: "#3E7EFD",
                        cursor: "pointer",
                      }}
                      w={"268px"}
                      h={"40px"}
                      bg="#F9F9F9"
                      borderBottomColor="#D4D4D4"
                      borderTopColor="#D4D4D4"
                      borderBottomWidth="1px"
                      borderTopWidth="1px"
                      mt={2}
                    >
                      <Text mr={12} pl={1} fontSize={14} fontFamily="sequel-sans-book-head">
                        PUERTO RICO (English)
                      </Text>
                    </Box>
                    <Box
                      as="button"
                      _hover={{
                        bgColor: "#3E7EFD",
                        cursor: "pointer",
                      }}
                      w={"268px"}
                      h={"40px"}
                      bg="#F9F9F9"
                      borderBottomColor="#D4D4D4"
                      borderBottomWidth="1px"
                    >
                      <Text mr={12} pl={2} fontSize={14} fontFamily="sequel-sans-book-head">
                        PUERTO RICO (Español)
                      </Text>
                    </Box>
                  </>
                )}

                <Link
                  as={ReactLink}
                  to="/choose-country-or-region"
                  _hover={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    as="button"
                    _hover={{
                      bgColor: "#3E7EFD",
                      cursor: "pointer",
                    }}
                    borderBottomRadius={18}
                    borderTopColor="#D4D4D4"
                    borderTopWidth={"1px"}
                    w={"268px"}
                    h={"40px"}
                    bg="#F9F9F9"
                    mt={2}
                  >
                    <HStack ml={9} spacing={20}>
                      <Text fontSize={14} fontFamily="sequel-sans-book-head">
                        OTHER COUNTRY
                      </Text>
                      <Icon
                        as={BsFillArrowRightCircleFill}
                        color="#E8E8E8"
                        boxSize={4}
                        _hover={{
                          color: "#E8E8E8",
                        }}
                      />
                    </HStack>
                  </Box>
                </Link>
              </Collapse>
            </Box>
          </Box>

          <Button
            colorScheme="black"
            type="submit"
            variant="outline"
            borderRadius={50}
            ml={-20}
            w={[90, 60, 28, 28]}
            bg="#BEBEBE"
            border="none"
            _hover={{
              bgColor: "#3E7EFD",
            }}
            position="fixed"
            right={[null, null, 150, 150, 300, 580]}
            top={[null, null, 3, 3, 3, 2]}
            onClick={onClose}
          >
            <Text fontFamily="sequel-sans-roman-body" fontSize={15} color="white">
              Continue
            </Text>
          </Button>
          <CloseButton
            alignSelf="flex-start"
            position="fixed"
            right={[null, null, 120, 100, 260, 540]}
            top={[null, null, 5, 5]}
            mt={-1}
            onClick={onClose}
            _hover={{
              color: "none",
            }}
            color="#7A7A7A"
          />
        </Box>

        {/*Mobile and tablets bar */}
        <Box
          bg="#F9F9F9"
          h={40}
          w="full"
          display={{ base: "flex", lg: "none" }}
          position="fixed"
          top={-2}
          zIndex={99999}
        >
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <HStack>
              <Text
                fontFamily="sequel-sans-book-head"
                position="relative"
                right={6}
                top={[-8, -8]}
                fontSize={14}
                px={10}
                py={2}
                color="#1C1D1F"
              >
                Please confirm the country or choose the country relevant to display the right content.
              </Text>
              <CloseButton
                position="fixed"
                right={[2, 3]}
                top={[4, 4, 6]}
                onClick={onClose}
                _hover={{
                  color: "none",
                }}
                color="#7A7A7A"
              />
            </HStack>
          </Box>

          <Box pos="fixed" top={"75px"} left={4}>
            <HStack>
              <Box
                w={["200px", "240px"]}
                h="45px"
                bg="#F9F9F9"
                mt={4}
                mr={[10, 8, 6]}
                onClick={onToggle}
                _hover={{ cursor: "pointer" }}
              >
                <HStack mt={2}>
                  <Icon as={BsCheck2} color="#3E7EFD" boxSize={5} />
                  <Text
                    pl={2}
                    pr={10}
                    color="#3E7EFD"
                    fontFamily="sequel-sans-book-head"
                    fontSize={[12, 14]}
                    textTransform="uppercase"
                  >
                    {country}
                  </Text>
                  <Icon as={BsChevronDown} color="#7A7A7A" boxSize={4} pos="fixed" left={["183px", "216px"]} />
                </HStack>
                <Collapse in={isOpen} animateOpacity transition={{ enter: { duration: 1 }, exit: { duration: 1 } }}>
                  {country === "Canada" && (
                    <>
                      <Box
                        as="button"
                        _hover={{
                          bgColor: "#3E7EFD",
                          cursor: "pointer",
                        }}
                        w={["200px", "240px"]}
                        h={["32px", "38px"]}
                        bg="#F9F9F9"
                        borderBottomColor="#D4D4D4"
                        borderTopColor="#D4D4D4"
                        borderBottomWidth="1px"
                        borderTopWidth="1px"
                        mt={2}
                      >
                        <Text mr={[8, 14]} fontSize={[12, 14]} fontFamily="sequel-sans-book-head">
                          CANADA (English)
                        </Text>
                      </Box>
                      <Box
                        as="button"
                        _hover={{
                          bgColor: "#3E7EFD",
                          cursor: "pointer",
                        }}
                        w={["200px", "240px"]}
                        h={["32px", "38px"]}
                        bg="#F9F9F9"
                        borderBottomColor="#D4D4D4"
                        borderBottomWidth="1px"
                      >
                        <Text mr={[4, 10]} pr={2} fontSize={[12, 14]} fontFamily="sequel-sans-book-head">
                          CANADA (Français)
                        </Text>
                      </Box>
                    </>
                  )}

                  {country === "Puerto Rico" && (
                    <>
                      <Box
                        as="button"
                        _hover={{
                          bgColor: "#3E7EFD",
                          cursor: "pointer",
                        }}
                        w={["200px", "240px"]}
                        h={["32px", "38px"]}
                        bg="#F9F9F9"
                        borderBottomColor="#D4D4D4"
                        borderTopColor="#D4D4D4"
                        borderBottomWidth="1px"
                        borderTopWidth="1px"
                        mt={2}
                      >
                        <Text mr={[2, 6]} pl={1} fontSize={[12, 14]} fontFamily="sequel-sans-book-head">
                          PUERTO RICO (English)
                        </Text>
                      </Box>
                      <Box
                        as="button"
                        _hover={{
                          bgColor: "#3E7EFD",
                          cursor: "pointer",
                        }}
                        w={["200px", "240px"]}
                        h={["32px", "38px"]}
                        bg="#F9F9F9"
                        borderBottomColor="#D4D4D4"
                        borderBottomWidth="1px"
                      >
                        <Text mr={[2, 6]} pl={2} fontSize={[12, 14]} fontFamily="sequel-sans-book-head">
                          PUERTO RICO (Español)
                        </Text>
                      </Box>
                    </>
                  )}
                  <Link
                    as={ReactLink}
                    to="/choose-country-or-region"
                    _hover={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      as="button"
                      _hover={{
                        bgColor: "#3E7EFD",
                        cursor: "pointer",
                      }}
                      borderBottomRadius={18}
                      borderTopColor="#D4D4D4"
                      borderTopWidth={"1px"}
                      w={["200px", "240px"]}
                      h={["32px", "38px"]}
                      bg="#F9F9F9"
                      mt={2}
                    >
                      <HStack ml={9} spacing={[10, 14]}>
                        <Text fontSize={[12, 14]} fontFamily="sequel-sans-book-head" color="#333333">
                          OTHER COUNTRY
                        </Text>
                        <Icon
                          as={BsFillArrowRightCircleFill}
                          color="#E8E8E8"
                          boxSize={4}
                          _hover={{
                            color: "#E8E8E8",
                          }}
                        />
                      </HStack>
                    </Box>
                  </Link>
                </Collapse>
              </Box>
              <Button
                colorScheme="black"
                type="submit"
                variant="outline"
                borderRadius={50}
                w={[24, 28, null, null]}
                bg="#BEBEBE"
                border="none"
                _hover={{
                  bgColor: "#3E7EFD",
                }}
                position="fixed"
                left={[230, 280, null, null]}
                top={["90px"]}
                onClick={onClose}
              >
                <Text fontFamily="sequel-sans-roman-body" fontSize={[14, 15, 15]} color="white">
                  Continue
                </Text>
              </Button>
            </HStack>
          </Box>
        </Box>
      </>
    )
  );
};

export default LanguageAlert;
