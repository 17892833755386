import { parcksCrew } from "../../constants/constants";
import parcksGrayLogo from "./../../assets/gray_logo.png";
import parcksBlackLogo from "./../../assets/black_logo.png";
import { Link as ReactLink, useParams } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { BsFillArrowRightCircleFill, BsPlayCircleFill } from "react-icons/bs";
import { useNotificationBarVisibility } from "../../context/AutoCountryDetectionBarContext";
import { Box, Flex, HStack, Image, Stack, Heading, Text, Link, Icon } from "@chakra-ui/react";

const ParcksCrewMember = () => {
  //retrieve the crew member id from the url
  const { id } = useParams();

  //get the crew member with the specified ID
  const crewMember = parcksCrew.find(({ crewMemberId }) => crewMemberId == id);

  //language selection bar visibility
  const isVisible = useNotificationBarVisibility();

  return (
    <>
      <BreadCrumb activeLink fullName={crewMember.fullName} />
      <Box
        w="full"
        h={["1700px", "1700px", "1600px", "860px"]}
        bg="#FAFAFA"
        mt={isVisible ? [0, 0, 20, 0] : [-40, -40, -14]}
      >
        <Stack direction={{ base: "column", lg: "row" }}>
          <Box maxW={["600px", "600px", "750px", "600px", "600px", "900px"]}>
            <HStack mt={[60, 60, 40]} gap={2} pl={[4, 4, 4, 10, 10, 80]}>
              <Heading color="#1C1D1F" fontFamily="sequel-sans-semi-bold-head" fontSize={[20, 40]}>
                {crewMember.fullName}
              </Heading>
              <Image src={parcksBlackLogo} alt="Parcks Black Logo" boxSize={["1.2rem", "1.8rem"]} />
            </HStack>

            <Flex alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
              <Stack spacing={0}>
                <Text
                  fontFamily="sequel-sans-roman-head"
                  color="#1C1D1F"
                  pl={[3, 3, 3, 8, 8, 80]}
                  ml={[1, 1, 1, 2, 2, 0]}
                >
                  {crewMember.title}
                </Text>
                <Text
                  fontFamily="sequel-sans-light-body"
                  color="#1C1D1F"
                  pr={[12, 12, 12, 12, 10, 14]}
                  pl={[4, 4, 4, 10, 10, 80]}
                >
                  {crewMember.roleAbbreviation === "CPO" ? crewMember.role : crewMember.roleAbbreviation}
                </Text>
              </Stack>
            </Flex>

            <Box px={[4, 4, 0]}>
              <Text fontFamily="sequel-sans-roman-body" fontSize={14} pl={[0, 0, 4, 10, 10, 80]} mt={10}>
                {crewMember.biography}
              </Text>
            </Box>

            <CrewPageLink
              linkText="Parcks History"
              linkHref="/parcks-history"
              icon={BsFillArrowRightCircleFill}
              marginTop={10}
            />
            <CrewPageLink
              linkText="Watch the interview"
              linkHref="/watch-the-interview"
              icon={BsPlayCircleFill}
              marginTop={4}
            />
          </Box>
          <Box pl={[0, 0, 0, 20, 20, 20]} h={["800px", "740px"]}>
            <Image
              src={crewMember.image}
              alt={crewMember.fullName}
              h={[400, 700, 700, "600px"]}
              w={"full"}
              mt={[14, 14, 14, 40]}
            />
          </Box>
        </Stack>
      </Box>
      <Box bg="#FFFFFF" h="1024px" w="full" mb={[40, 0]}>
        <Flex alignItems="center" justifyContent="center">
          <Image
            src={parcksGrayLogo}
            alt="Parcks Gray Logo"
            mt={["450px", "400px", "400px", "400px", "400px", "450px"]}
            h={["246px"]}
            w={["246px"]}
          />
        </Flex>
      </Box>
    </>
  );
};

const CrewPageLink = ({ linkText, linkHref, icon, marginTop }) => {
  return (
    <Box pl={[4, 4, 4, 10, 10, 80]} mt={marginTop}>
      <Link
        as={ReactLink}
        to={linkHref}
        color="#3E7EFD"
        fontWeight={500}
        fontFamily="sequel-sans-medium-head"
        fontSize={[12, 16, 12, 14, 16]}
        _hover={{
          textDecoration: "none",
          cursor: "pointer",
        }}
      >
        {linkText}
        <Icon as={icon} color="#3E7EFD" boxSize={4} position="relative" left={[1]} top={[1, 0.9, 0.6, 1]} />
      </Link>
    </Box>
  );
};

export default ParcksCrewMember;
