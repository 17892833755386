import "./Hero.css";
import modelSSmallFullFinal from "./../../assets/model-S-dark-scene-final.png";
import { useNotificationBarVisibility } from "../../context/AutoCountryDetectionBarContext";
import { Text, Heading, Box, Image, Flex  } from "@chakra-ui/react";

const Hero = () => {
  const isVisible = useNotificationBarVisibility();
 
  return (
    <>
      <Flex direction="column" justify="end" bg="#000"   h="100vh"    mt={isVisible ? [40,40,20]: 0}  >
        <Image
          src={modelSSmallFullFinal}
          alt="Capsule"
          objectFit={["cover","contain"]}
          objectPosition="center" 
          width={{ base: "120%", md: "100%" }} 
          height="100%"
       
        />
      </Flex>
      <Box className={"hero-content-2"} py={[5, 10, 10, 2]} >
          <Box className={"hero-content-conteiner-2"}>
            <Box className="intro-sticky-2" >
              <Box className={"intro-content"} mt={["-40px","-70px",10,"75px",10]}  p={[1, 1, 40]}>
                <Heading
                  fontSize={[46, 50, 60, 90]}
                  className="intro-subhead"
                  fontFamily="sequel-sans-bold-disp"
                >
                  STAND OUT.
                </Heading>

                <Text className="intro-paragraph" px={[2, 8, 6, 30]} fontSize={[16, 17, 17, 21]}>
                  What would be our world without those who have been marginal enough to keep dreaming. To dream that
                  there is a different path than the one preached. Dream is this inexhaustible fuel that makes perfect
                  each of those short but so meaningful moments of our lives.
                </Text>
                <Text className="intro-paragraph" px={[2, 8, 6, 30]} fontSize={[16, 17, 17, 21]}>
                  Children of the Internet Nation, living without any borders, any frontiers and any prejudices of any
                  kind. Refusing the old world and the old ways of thinking. Because their mind is too small, but yet
                  the sky is so extended. They called us utopians, we call us humans. Everything is here. All the value
                  of this company is here. In those who stand out everyday to change something.
                </Text>
                <Text className="intro-paragraph" px={[2, 8, 6, 30]} fontSize={[16, 17, 17, 21]}>
                  What would be our world without the stubborns and the passionates ones that have been on the front
                  side to keep the dream alive. So we choose to stand out, reduce to silence the voices of the
                  self-called reasonable ones. We believe it is essential to redefine the world as we know it, to break
                  the rules, to stand out from the crowd and the common thinking.
                </Text>
                <Text
                  className="intro-paragraph"
                  px={[2, 8, 6, 30]}
                  fontSize={[16, 17, 17, 21]}
                  
                >
                  Nothing is worse than a narrow mind, look much further than anybody else. Be smart enough to be
                  different, be lost enough to find, be wise enough to be mad.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
    </>
  );
};

export default Hero;
