import axios from "axios";

//set the url for the entire application
//dev
//comment it out when deploying to production
const BASE_URL = "http://localhost:8000";

//prod
//uncomment for production
//const BASE_URL = "https://parcks.com";

export default axios.create({
  baseURL: BASE_URL,
});
