import {
  Box,
  Flex,
  Link,
  Image,
  chakra,
  HStack,
  VStack,
  Collapse,
  Icon,
  useDisclosure,
  VisuallyHidden,
} from "@chakra-ui/react";
import { useState } from "react";
import Hamburger from "../Hamburger/Hamburger";
import blackLogo from "./../../assets/black_logo.png";
import LanguageAlert from "../LanguageAlert/LanguageAlert";
import { AiOutlineSearch, AiFillShopping } from "react-icons/ai";
import { useNotificationBarVisibility } from "../../context/AutoCountryDetectionBarContext";
import { Link as ReactLink } from "react-router-dom";

const CustomLink = ({ to, children, ...props }) => {
  const baseStyles = {
    fontSize: "10px",
    fontFamily: "sequel-sans-roman-body",
    pr: [null, 8, 8, 2],
    color: "#333333",
    _hover: { color: "#000000" },
    ...props,
  };

  return (
    <chakra.div as={ReactLink} to={to} {...baseStyles}>
      {children}
    </chakra.div>
  );
};

const Header = () => {
  const isVisible = useNotificationBarVisibility();
  const mobileNav = useDisclosure();
  const collapse = useDisclosure();
  const [isOpened, setOpened] = useState(false);

  const handleMobileNavToggle = () => {
    mobileNav[isOpened ? "onClose" : "onOpen"]();
    collapse.onToggle();
    setOpened(!isOpened);
  };

  const links = [
    { to: "/parcks", text: "PARCKS", display: "" },
    { to: "#", text: "CAPSULE", display: "" },
    { to: "#", text: "HUB", display: "none" },
    { to: "#", text: "SUPPORT", display: "" },
  ];

  return (
    <>
      <LanguageAlert />
      <Box
        w="full"
        h={46}
        bg="#F2F2F3"
        position="fixed"
        left={0}
        top={isVisible ? [32, 36, 32, 14, 14, 14] : [-6, -2, 0, -2]}
        mt={isVisible ? [5, 2, 6, 2, 2, 2] : [6, 2, 0, 2]}
        zIndex={9999}
        py={[2.5, 3.5]}
      >
        <Flex alignItems="center" justifyContent="center" mx="auto">
          <HStack display="flex" alignItems="center">
            <HStack gap={[null, 19, 19, 20]} display={{ base: "none", md: "inline-flex" }}>
              <Link href="/" title="Home Page" pr={[null, 4, 4, 2]}>
                <Image src={blackLogo} w={4} h={4} alt="Parcks Small Black Logo" />
                <VisuallyHidden>Home Page</VisuallyHidden>
              </Link>
              {links.map((link, index) => (
                <CustomLink key={index} to={link.to} display={link.display}>
                  {link.text}
                </CustomLink>
              ))}
              <chakra.a
                href="#"
                pr={[null, 8, 8, 2]}
                color="#333333"
                _hover={{
                  color: "#000000",
                }}
              >
                <AiOutlineSearch size={16} />
                <VisuallyHidden>Search</VisuallyHidden>
              </chakra.a>
              <chakra.a
                href="#"
                pr={[null, 4, 4, 2]}
                color="#333333"
                _hover={{
                  color: "#000000",
                }}
                display="none"
              >
                <AiFillShopping size={18} />
                <VisuallyHidden>Shop</VisuallyHidden>
              </chakra.a>
            </HStack>

            <Box display={{ base: "flex", md: "none" }}>
              <HStack display="flex" gap={[28, 40]} pt={-2} mt={[-2, -3]} mr={12}>
                <Box pos="fixed" top={isVisible ? "116px" : "-38px"} left={"14px"}>
                  <Hamburger handleMobileNavToggle={handleMobileNavToggle} isOpened={isOpened} />
                </Box>

                <chakra.a href="/">
                  <Image
                    src={blackLogo}
                    w={"23px"}
                    h={"23px"}
                    mt={3}
                    ml={[10, 4]}
                    alt="Parcks Small Black Logo"
                    pos="relative"
                    left={isVisible ? [-2, 1] : [-2, 2]}
                  />
                </chakra.a>
                <Icon as={AiFillShopping} boxSize={7} pos="fixed" top={isVisible ? 40 : 2} right={3} />
              </HStack>

              <VStack
                pos="absolute"
                top={1}
                left={0}
                right={0}
                bg="#F2F2F3"
                mt={10}
                mb={20}
                spacing={3}
                rounded="sm"
                shadow="sm"
                w="full"
              >
                <Collapse
                  in={collapse.isOpen}
                  animateOpacity
                  transition={{ enter: { duration: 1 }, exit: { duration: 1 } }}
                >
                  <Box bg="#F2F2F3" h="100vh" w="100vw">
                    <Icon as={AiOutlineSearch} boxSize={4} pos="fixed" top={isVisible ? "215px" : 16} left={5} />
                    <VStack gap={4}>
                      {links.map((link, index) => (
                        <CustomLink
                          key={index}
                          to={link.to}
                          fontWeight="600"
                          fontSize={14}
                          color="#333333"
                          _hover={{
                            color: "#000000",
                          }}
                          pt={index === 0 ? 14 : undefined}
                          onClick={handleMobileNavToggle}
                          display={link.display}
                        >
                          {link.text}
                        </CustomLink>
                      ))}
                    </VStack>
                  </Box>
                </Collapse>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default Header;
