import { useEffect } from "react";
import Home from "./pages/Home/Home";
import Parcks from "./pages/Parcks/Parcks";
import Country from "./pages/Country/Country";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ParcksCrew from "./pages/Crew/ParcksCrew";
import ParcksCrewMember from "./pages/Crew/ParcksCrewMember";
import { Route, Routes, useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <div>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/parcks" element={<Parcks />} />
        <Route path="/parcks-leading-crew" element={<ParcksCrew />} />
        <Route path="/parcks-leading-crew/:id" element={<ParcksCrewMember />} />
        <Route path="/choose-country-or-region" element={<Country />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
