import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as ReactLink } from "react-router-dom";
import { Flex, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import { useNotificationBarVisibility } from "../../context/AutoCountryDetectionBarContext";

const BreadCrumb = ({ activeLink, fullName }) => {
  const isVisible = useNotificationBarVisibility();
  return (
    <>
      <Flex
        w="full"
        h="72px"
        pos="relative"
        top={isVisible ? ["194px", "198px", "198px", "104px", "104px", "104px"] : "42px"}
        bg="#ECF2FF"
        flexDirection="row"
        alignItems="flex-start"
      >
        <Breadcrumb separator={<ChevronRightIcon color="#1C1D1F" />} py={6} pl={[2, 4, 10]}>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={ReactLink}
              color="#1C1D1F"
              to="/parcks"
              _hover={{
                textDecoration: "none",
                color: "#A3A3A3",
              }}
              fontFamily="sequel-sans-semi-bold-head"
              fontSize="16px"
            >
              Parcks
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={ReactLink}
              to="/parcks-leading-crew"
              _hover={{
                textDecoration: "none",
                color: "#A3A3A3",
              }}
              pl={[0, 0, 12, 12]}
              pt={1}
              fontFamily="sequel-sans-medium-head"
              fontSize="12px"
              color={activeLink ? "#A3A3A3" : "#1C1D1F"}
            >
              Leading Crew
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {fullName && (
          <Text
            fontFamily="sequel-sans-semi-bold-head"
            fontSize={16}
            color="#1C1D1F"
            pos="relative"
            top={["25.5px", 6, 6]}
            left={["30px", "120px", "330px", "550px", "860px", "1300px"]}
          >
            {fullName}
          </Text>
        )}
      </Flex>
    </>
  );
};

export default BreadCrumb;
