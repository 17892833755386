import job from "../../assets/job.svg";
import main from "../../assets/main.WEBP";
import { SimpleGrid } from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";
import { Link, Heading, Text } from "@chakra-ui/react";
import sp_background from "./../../assets/sp_background.png";
import { CustomHeading, BoxWithBgColor } from "../CustomComponents/CustomComponents";

const HomeSquares = () => {
  return (
    <SimpleGrid columns={[1, 1, 2, 2]} gap={2} p={2}>
      <BoxWithBgColor
        w="588"
        h={[400, 400, 400, 588]}
        borderRadius={15}
        style={{ backgroundImage: `url(${sp_background})`, backgroundSize: "100%" }}
        fontSize={32}
        pt={4}
        display="none"
      >
        <CustomHeading as="h1" size="4xl" fontSize={50} color="#323232">
          SP
        </CustomHeading>
        <CustomHeading fontSize={[20, 20, 20]} pt={2} fontFamily="sequel-sans-medium-body">
          Safe, Private, Simple.
        </CustomHeading>
        <Link
          href="#"
          color="#3E7EFD"
          fontWeight={500}
          display="flex"
          alignItems="center"
          justifyContent="center"
          _hover={{
            textDecoration: "none",
          }}
          fontSize={16}
          py={2}
          fontFamily="sequel-sans-medium-body"
        >
          Explore SP <BiChevronRight size={22} />
        </Link>
      </BoxWithBgColor>

      <BoxWithBgColor w="588" h={[400, 400, 400, 588]} bg="#000000" borderRadius={15} display="none">
        <Heading
          size="lg"
          fontSize="64"
          color="white"
          alignItems="black"
          justifyContent="center"
          fontFamily="sequel-sans-roman-body"
          pt={12}
          display={{ base: "none", md: "none", lg: "flex" }}
        >
          Pre-Orders
        </Heading>
        <Heading
          size="lg"
          fontSize="165px"
          color="white"
          alignItems="black"
          justifyContent="center"
          fontFamily="sequel-sans-semi-bold-disp"
          display={{ base: "none", md: "none", lg: "flex" }}
        >
          14
        </Heading>
        <Heading
          size="lg"
          color="white"
          fontSize="165px"
          alignItems="black"
          justifyContent="center"
          fontFamily="sequel-sans-semi-bold-disp"
          display={{ base: "none", md: "none", lg: "flex" }}
        >
          OCT
        </Heading>
        <Heading
          size="md"
          fontSize="50"
          color="white"
          alignItems="black"
          justifyContent="center"
          fontFamily="sequel-sans-roman-body"
          pt={12}
          display={{ base: "flex", md: "flex", lg: "none" }}
        >
          Pre-Orders
        </Heading>
        <Heading
          size="md"
          fontSize="100px"
          color="white"
          alignItems="black"
          justifyContent="center"
          fontFamily="sequel-sans-semi-bold-disp"
          display={{ base: "flex", md: "flex", lg: "none" }}
        >
          14
        </Heading>
        <Heading
          size="md"
          color="white"
          fontSize="100px"
          alignItems="black"
          justifyContent="center"
          fontFamily="sequel-sans-semi-bold-disp"
          display={{ base: "flex", md: "flex", lg: "none" }}
        >
          OCT
        </Heading>
      </BoxWithBgColor>
      <BoxWithBgColor w="588" h={[400, 400, 400, 588]} borderRadius={15}>
        <CustomHeading fontSize={32} pt={10}>
          Accessories
        </CustomHeading>
        <Text textAlign="center" fontFamily="sequel-sans-medium-body" fontSize="14px" color="##000000">
          Better than new,its different.
        </Text>
      </BoxWithBgColor>
      <BoxWithBgColor
        w="588"
        h={[400, 400, 400, 588]}
        mb={[10, 2]}
        borderRadius={15}
        style={{
          backgroundImage: `url(${job})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <CustomHeading fontSize={32} pt={10}>
          Jobs
        </CustomHeading>
        <Text textAlign="center" fontFamily="sequel-sans-medium-body" fontSize="14px" color="##000000">
          Welcome on board.
        </Text>
      </BoxWithBgColor>
    </SimpleGrid>
  );
};

export default HomeSquares;
