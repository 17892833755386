import Hero from "../../components/Hero/Hero";
import HomeSquares from "../../components/Squares/HomeSquares";
import HomeRectangles from "../../components/Rectangles/HomeRectangles";

const Home = () => {
  return (
    <>
      <Hero />
      <HomeRectangles />
      <HomeSquares />
    </>
  );
};

export default Home;
