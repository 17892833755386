import {
  Box,
  Link,
  Image,
  Stack,
  VStack,
  HStack,
  Container,
  Accordion,
  SimpleGrid,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import {
  CustomBox,
  CustomLink,
  ListHeader,
  CustomText,
  MobileMenuListHeader,
  CustomAccordionButton,
} from "../CustomComponents/CustomComponents";
import { Link as ReactLink } from "react-router-dom";
import earthLogo from "./../../assets/planet_earth_logo.png";
import { useCountry } from "../../context/AutoCountryDetectionBarContext";

const Footer = () => {
  const country = useCountry();

  return (
    <>
      <Box bg="#F9F9F9" display={{ base: "none", md: "grid" }}>
        <Container as={Stack} maxW={"6xl"} pt={80} px={20} mb={10}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={10} ml={[0, 0, 36, 60]}>
            <Stack>
              <ListHeader fontSize={[null, 16, 10, 14]}>PARCKS</ListHeader>
              <CustomLink href={"#"}>Core Stand</CustomLink>
              <CustomLink href={"#"}>Jobs</CustomLink>
              <CustomLink href={"#"} display="none">
                Investors
              </CustomLink>
              <CustomLink as={ReactLink} to="/parcks-leading-crew" display="none">
                Leading Crew
              </CustomLink>
              <CustomLink href={"#"} display="none">
                Contact
              </CustomLink>
            </Stack>
            <Stack>
              <ListHeader fontSize={[null, 16, 10, 14]}>ENGAGEMENTS</ListHeader>
              <CustomLink href={"#"}>Ethical Supply</CustomLink>
              <CustomLink href={"#"} display="none">
                Fair Trade
              </CustomLink>
              <CustomLink href={"#"} display="none">
                Traceability
              </CustomLink>
              <CustomLink href={"#"}>Privacy</CustomLink>
              <CustomLink href={"#"}>Accessibility</CustomLink>
              <CustomLink href={"#"}>Environnement</CustomLink>
            </Stack>
            <Stack>
              <ListHeader fontSize={[null, 16, 10, 14]}>PRODUCTS</ListHeader>
              <CustomLink href={"#"}>Capsule</CustomLink>
              <CustomLink href={"#"} display="none">
                Home
              </CustomLink>
              <CustomLink href={"#"}>Accessories</CustomLink>
            </Stack>
            <Stack display="none">
              <ListHeader fontSize={[null, 16, 10, 14]}>PARCKS HUB</ListHeader>
              <CustomLink href={"#"}>Community</CustomLink>
              <CustomLink href={"#"}>Open Projects</CustomLink>
              <CustomLink href={"#"}>Whats On Your Mind</CustomLink>
              <CustomLink href={"#"}>Find A Hub</CustomLink>
              <CustomLink href={"#"}>Events</CustomLink>
              <CustomLink href={"#"}>House Party</CustomLink>
              <CustomLink href={"#"}>Order Status</CustomLink>
              <CustomLink href={"#"}>Shopping Help</CustomLink>
            </Stack>

            <Stack display="none">
              <ListHeader fontSize={[null, 16, 10, 14]}>HUB ACCOUNT</ListHeader>
              <CustomLink href={"#"}>Access To My Space</CustomLink>
              <br />
              <br />
              <ListHeader fontSize={[null, 16, 10, 14]} display="none">
                {" "}
                SUPPORT
              </ListHeader>
              <CustomLink href={"#"}>Assistance</CustomLink>
            </Stack>
          </SimpleGrid>
        </Container>
        <Box py={10} pb={1} display="flex" direction="row" justifyContent="space-between">
          <CustomText pt={6} textAlign={"center"} pl={16}>
            2022 PARCKS INC. ALL RIGHTS RESERVED.
          </CustomText>

          <Link href={"#"}>
            <CustomText pt={6} textAlign={"center"}>
              PRIVACY POLICY
            </CustomText>
          </Link>
          <Link href={"#"}>
            <CustomText pt={6} textAlign={"center"} pr={12}>
              ACCESSIBILITY
            </CustomText>
          </Link>
          {/*Large Screens Language Selector Link */}
          <Link as={ReactLink} to="/choose-country-or-region" _hover={{ textDecoration: "none" }}>
            <HStack mr={10} mt={3}>
              <Image boxSize="1.6rem" src={earthLogo} alt="Planet Earth" ml="12px" mb={1} />
              <CustomText pb={1} textAlign={"center"} fontFamily="sequel-sans-book-head" textTransform="uppercase">
                {country}
              </CustomText>
            </HStack>
          </Link>
        </Box>
      </Box>

      {/*Small Screens Language Selector Link */}
      <Link as={ReactLink} to="/choose-country-or-region" _hover={{ textDecoration: "none" }}>
        <Stack
          direction="row"
          mr={10}
          mt={3}
          display={{ base: "flex", md: "none" }}
          alignItems="center"
          justifyContent="center"
        >
          <Image
            boxSize="1rem"
            src={earthLogo}
            alt="Planet Earth"
            ml="12px"
            mt={0}
            fontFamily="sequel-sans-medium-head"
          />
          <CustomText textTransform="uppercase">{country}</CustomText>
        </Stack>
      </Link>

      <Accordion display={{ base: "grid", md: "none" }} mt={10} mx={4} p={4}>
        <AccordionItem>
          <h2>
            <CustomAccordionButton>
              <CustomBox>
                <MobileMenuListHeader>PARCKS</MobileMenuListHeader>
              </CustomBox>
            </CustomAccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack align={"flex-start"}>
              <CustomLink as={ReactLink} to="/parcks" display="none">
                {" "}
                PARCKS
              </CustomLink>
              <CustomLink href={"#"}>Core Stand</CustomLink>
              <CustomLink href={"#"}>Jobs</CustomLink>
              <CustomLink href={"#"} display="none">
                Investors
              </CustomLink>
              <CustomLink as={ReactLink} to="/parcks-leading-crew" display="none">
                Leading Crew
              </CustomLink>
              <CustomLink href={"#"} display="none">
                Contact
              </CustomLink>
            </VStack>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <CustomAccordionButton>
              <CustomBox>
                <MobileMenuListHeader>ENGAGEMENTS</MobileMenuListHeader>
              </CustomBox>
            </CustomAccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack align={"flex-start"}>
              <CustomLink href={"#"}>Ethical Supply</CustomLink>
              <CustomLink href={"#"} display="none">
                Fair Trade
              </CustomLink>
              <CustomLink href={"#"} display="none">
                Traceability
              </CustomLink>
              <CustomLink href={"#"}>Privacy</CustomLink>
              <CustomLink href={"#"}>Accessibility</CustomLink>
              <CustomLink href={"#"}>Environnement</CustomLink>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <CustomAccordionButton>
              <CustomBox>
                <MobileMenuListHeader>PRODUCTS</MobileMenuListHeader>
              </CustomBox>
            </CustomAccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack align={"flex-start"}>
              <CustomLink href={"#"}>Capsule</CustomLink>
              <CustomLink href={"#"} display="none">
                Home
              </CustomLink>
              <CustomLink href={"#"}>Accessories</CustomLink>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem display="none">
          <h2>
            <CustomAccordionButton>
              <CustomBox>
                <MobileMenuListHeader>PARCKS HUB</MobileMenuListHeader>
              </CustomBox>
            </CustomAccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack align={"flex-start"}>
              <CustomLink href={"#"}>Community</CustomLink>
              <CustomLink href={"#"}>Open Projects</CustomLink>
              <CustomLink href={"#"}>Whats On Your Mind</CustomLink>
              <CustomLink href={"#"}>Find A Hub</CustomLink>
              <CustomLink href={"#"}>Events</CustomLink>
              <CustomLink href={"#"}>House Party</CustomLink>
              <CustomLink href={"#"}>Order Status</CustomLink>
              <CustomLink href={"#"}>Shopping Help</CustomLink>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem display="none">
          <h2>
            <CustomAccordionButton>
              <CustomBox>
                <MobileMenuListHeader>HUB ACCOUNT</MobileMenuListHeader>
              </CustomBox>
            </CustomAccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack align={"flex-start"}>
              <CustomLink href={"#"}>Access To My Space</CustomLink>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem display="none">
          <h2>
            <CustomAccordionButton borderBottomColor="black" borderBottom="1px">
              <CustomBox>
                <MobileMenuListHeader>SUPPORT</MobileMenuListHeader>
              </CustomBox>
            </CustomAccordionButton>
          </h2>
          <AccordionPanel pb={4} borderBottomColor="black" borderBottom="1px">
            <VStack align={"flex-start"}>
              <CustomLink href={"#"}>Assistance</CustomLink>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <HStack display={{ base: "flex", md: "none" }} alignItems="center" justifyContent="space-between" mt={12} p={4}>
        <CustomText pt={6}>2022 PARCKS INC.</CustomText>
        <Link href={"#"}>
          <CustomText pt={6}>PRIVACY POLICY</CustomText>
        </Link>
      </HStack>
    </>
  );
};

export default Footer;
