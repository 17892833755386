import capsule from "../../assets/capsule.svg";
import { BiChevronRight } from "react-icons/bi";
import { IoPlayCircle } from "react-icons/io5";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as ReactLink } from "react-router-dom";
import { CustomHeading } from "../CustomComponents/CustomComponents";
import { Text, Box, VStack, HStack, Flex, Link, useBreakpointValue } from "@chakra-ui/react";
import { useNotificationBarVisibility } from "../../context/AutoCountryDetectionBarContext";

const ParcksRectangle = ({
  backgroundColor,
  bgImage,
  boldCapitalText,
  blueCapitalText,
  blackSmallCaseText,
  boldBlackSmallCaseText,
}) => {
  //responsive sizes for the chevron right icon
  const iconSize = useBreakpointValue([16, 20]);

  //auto country detection bar visibility status
  const isVisible = useNotificationBarVisibility();

  return (
    <>
      <Box
        h="600px"
        w="full"
        bg={backgroundColor ? backgroundColor : ""}
        bgImage={bgImage ? `url(${capsule})` : ""}
        bgPosition={bgImage ? "center" : ""}
        bgRepeat={bgImage ? "no-repeat" : ""}
      >
        {boldCapitalText && (
          <CustomHeading
            fontSize={[55, 80, 120, 160]}
            textTransform="uppercase"
            fontFamily="sequel-sans-bold-head"
            py={20}
            pt={isVisible ? [80, 80, 80, 40] : [40]}
          >
            {boldCapitalText}
          </CustomHeading>
        )}

        {blueCapitalText && (
          <Box display="flex" alignItems="center" justifyContent="center" mt={-30}>
            <Link
              as={ReactLink}
              color="#3E7EFD"
              fontWeight={500}
              display="flex"
              alignItems="center"
              justifyContent="center"
              to="/watch-the-film"
              textTransform="uppercase"
              fontFamily="sequel-sans-medium-body"
              fontSize={[12, 14]}
              _hover={{
                textDecoration: "none",
                cursor: "pointer",
              }}
              py={2}
            >
              <HStack spacing="-2px">
                <Text>{blueCapitalText}</Text>
                <ChevronRightIcon boxSize={5} pos="relative" top={["-0px"]} />
              </HStack>
            </Link>
          </Box>
        )}

        {blackSmallCaseText && (
          <Flex alignItems="center" justifyContent="center">
            <VStack>
              <Text fontSize={[10, 14, 16, 18]} pt={60} fontFamily="sequel-sans-book-body">
                Created in march 2019 in Brighton, United Kingdom,
              </Text>
              <Text fontSize={[10, 14, 16, 18]} pb={40} fontFamily="sequel-sans-book-body">
                Parcks is a company developing consumer electronics products.
              </Text>

              <Link
                as={ReactLink}
                color="#3E7EFD"
                fontWeight={500}
                display="flex"
                alignItems="center"
                justifyContent="center"
                to="/watch-the-film"
                fontFamily="sequel-sans-medium-head"
                fontSize={[12, 14]}
                _hover={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                py={2}
              >
                <HStack spacing="-2px">
                  <Text> Download General Presentation of Parcks (PDF)</Text>
                  <ChevronRightIcon boxSize={5} pos="relative" top={["1px"]} />
                </HStack>
              </Link>
            </VStack>
          </Flex>
        )}

        {boldBlackSmallCaseText && (
          <>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Text
                fontSize={[16, 24, 30]}
                color="#1C1D1F"
                pt={60}
                mt={[-26, 26, -26]}
                fontFamily="sequel-sans-bold-head"
              >
                “Nothing is worse than a narrow mind.
              </Text>
              <Text fontSize={[16, 24, 30]} color="#1C1D1F" fontFamily="sequel-sans-bold-head">
                Look much further than anybody else.
              </Text>
              <Text fontSize={[16, 24, 30]} pr={[10, 16, 20]} color="#1C1D1F" fontFamily="sequel-sans-bold-head">
                Be smart enough to be different.
              </Text>
              <Text
                fontSize={[16, 24, 30]}
                pr={[20, 28, 40]}
                ml={[1, -1, 4]}
                color="#1C1D1F"
                fontFamily="sequel-sans-bold-head"
              >
                Be wise enough to be mad.”
              </Text>

              <Text
                fontSize={[12, 15, 17, 19]}
                fontFamily="sequel-sans-semi-bold-head"
                color="#000000"
                ml={[20, 40, 60, 60]}
                pl={[20, 20, 36, 40]}
                pt={12}
              >
                Friedmann W. Bouithy
              </Text>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ParcksRectangle;
