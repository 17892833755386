import axios from "../api/axios";
import { useDisclosure } from "@chakra-ui/react";
import { countries } from "../constants/constants";
import { createContext, useEffect, useState, useContext } from "react";

const AutoCountryDetectionBarContext = createContext();
const LanguageContext = createContext();
const NotificationBarVisibilityContext = createContext();
const NotificationBarCloseContext = createContext();

export function useCountry() {
  return useContext(AutoCountryDetectionBarContext);
}

export function useLanguage() {
  return useContext(LanguageContext);
}

export function useNotificationBarVisibility() {
  return useContext(NotificationBarVisibilityContext);
}

export function useNotificationBarClose() {
  return useContext(NotificationBarCloseContext);
}

export function AutoCountryDetectionBarProvider({ children }) {
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");

  //auto country detection bar toggle
  const { isOpen: isVisible, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    //get users current location
    const detectUsersLocation = async () => {
      const result = await axios.get("https://ipapi.co/json/");

      //free api but need country name to be converted to lower case or search with capital city
      //const result = await axios.get("https://api.hostip.info/get_json.php");

      setCountry(result.data.country_name);

      //get detected countries offical language
      const found = countries.find((obj) => {
        return obj.name === result.data.country_name;
      });

      setLanguage(found.language.code);
      onOpen();
    };

    detectUsersLocation();
  }, []);

  return (
    <AutoCountryDetectionBarContext.Provider value={country}>
      <LanguageContext.Provider value={language}>
        <NotificationBarVisibilityContext.Provider value={isVisible}>
          <NotificationBarCloseContext.Provider value={onClose}>{children}</NotificationBarCloseContext.Provider>
        </NotificationBarVisibilityContext.Provider>
      </LanguageContext.Provider>
    </AutoCountryDetectionBarContext.Provider>
  );
}
