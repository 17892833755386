import {
  Box,
  Text,
  Link,
  Menu,
  Image,
  Stack,
  HStack,
  Heading,
  VStack,
  Collapse,
  MenuButton,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import mars from "./../../assets/mars.jpg";
import { FaChevronDown } from "react-icons/fa";
import { BiChevronRight } from "react-icons/bi";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as ReactLink } from "react-router-dom";
import antarctica from "./../../assets/antarctica.jpg";
import planetOrbit from "./../../assets/planet_orbit.jpg";
import {
  EarthImage,
  PlanetText,
  CountryLink,
  CustomHeading,
  CountryHeading,
} from "../../components/CustomComponents/CustomComponents";
import earthBlueLogo from "./../../assets/planet_earth_logo_blue.png";
import { useNotificationBarVisibility } from "../../context/AutoCountryDetectionBarContext";

const Country = () => {
  const isVisible = useNotificationBarVisibility();
  //applies a slide down transition to the select your planet options
  const { isOpen, onToggle } = useDisclosure();

  const [planet, setPlanet] = useState("Select your planet");

  //sets the planet the user selected and closes the drop down
  const handleSetPlanet = (selectedPlanted) => {
    setPlanet(selectedPlanted);
    onToggle();
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt={isVisible ? [64, 64, 60, 40] : 24}>
        <VStack>
          <Heading fontFamily="sequel-sans-medium-head" fontSize={[20, 30]} pb={[14, 14, 20]}>
            Choose your country
          </Heading>
          <Text fontFamily="sequel-sans-light-head" fontSize={[13, 18, 19, 20]} pb={[14, 14, 20]} display="none">
            (If it is not in the list, please choose “other” displayed below)
          </Text>
        </VStack>
      </Box>

      <Box pl={[14, 20, 12, 60]} pt={4} pb={[5, 6, 10, 16]}>
        <Text fontSize={14} fontFamily="sequel-sans-semi-bold-head" color="#8D8D94">
          Interplanetary Space
        </Text>

        <Menu>
          <MenuButton
            pt={4}
            border="none"
            fontFamily="sequel-sans-book-head"
            _hover={{
              color: "#000000",
            }}
            color="#777777"
            onClick={onToggle}
          >
            <HStack>
              <Image boxSize={["1.3rem", "1.6rem"]} src={planetOrbit} alt="Planet Orbit" />
              <Text fontSize={[16, 18]}>{planet}</Text> <FaChevronDown />
            </HStack>
          </MenuButton>
          <Collapse in={isOpen} animateOpacity transition={{ enter: { duration: 1 }, exit: { duration: 1 } }}>
            <PlanetText onClick={() => handleSetPlanet("EARTH")}>EARTH </PlanetText>
            <PlanetText onClick={() => handleSetPlanet("MARS")}>MARS </PlanetText>
          </Collapse>
        </Menu>
      </Box>
      {planet === "EARTH" && (
        <>
          <Box>
            <HStack mr={10} mt={10} mb={8}>
              <CountryHeading>Africa</CountryHeading>
              <EarthImage src={earthBlueLogo} alt="Planet Earth Blue Logo" />
            </HStack>
          </Box>
          <SimpleGrid columns={[1, 1, 3, 3, 3, 3]} pl={[14, 20, 14, 60]}>
            <Stack>
              <CountryLink>Algérie</CountryLink>
              <CountryLink>الجزائر</CountryLink>
              <CountryLink>Botswana</CountryLink>
              <CountryLink>Cameroun</CountryLink>
              <CountryLink>République Centrafricaine</CountryLink>
              <CountryLink>Côte d'Ivoire</CountryLink>
              <CountryLink>Congo</CountryLink>
              <CountryLink>Egypt</CountryLink>
              <CountryLink>مصر </CountryLink>
              <CountryLink>Ethiopia</CountryLink>
              <CountryLink>Kenya</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>Lybia</CountryLink>
              <CountryLink>ليبيا</CountryLink>
              <CountryLink>Ghana</CountryLink>
              <CountryLink>Guinea-Bissau</CountryLink>
              <CountryLink>Guinée</CountryLink>
              <CountryLink>Guinée Equatoriale</CountryLink>
              <CountryLink>Madagascar</CountryLink>
              <CountryLink>Mali</CountryLink>
              <CountryLink>Maroc</CountryLink>
              <CountryLink>المغرب</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>Maurice</CountryLink>
              <CountryLink>Mozambique</CountryLink>
              <CountryLink>Niger</CountryLink>
              <CountryLink>Nigeria</CountryLink>
              <CountryLink>RD du Congo</CountryLink>
              <CountryLink>Sénégal</CountryLink>
              <CountryLink>South Africa</CountryLink>
              <CountryLink>Tunisie</CountryLink>
              <CountryLink>Uganda</CountryLink>
            </Stack>
          </SimpleGrid>

          <Box>
            <HStack mr={10} mt={[10, 20]} mb={8}>
              <CountryHeading>Asia Pacific</CountryHeading>
              <EarthImage src={earthBlueLogo} alt="Planet Earth Blue Logo" />
            </HStack>
          </Box>

          <SimpleGrid columns={[1, 1, 3, 3, 3, 3]} pl={[14, 20, 14, 60]}>
            <Stack>
              <CountryLink>Australia</CountryLink>
              <CountryLink>中国大陆</CountryLink>
              <CountryLink>Hong Kong (English)</CountryLink>
              <CountryLink>香港 </CountryLink>
              <CountryLink>Indonesia</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>日本</CountryLink>
              <CountryLink>대한민국</CountryLink>
              <CountryLink>澳門</CountryLink>
              <CountryLink>Malaysia</CountryLink>
              <CountryLink>New Zealand</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>Philippines</CountryLink>
              <CountryLink>Singapore</CountryLink>
              <CountryLink>台灣</CountryLink>
              <CountryLink>ไทย</CountryLink>
              <CountryLink>Vietnam</CountryLink>
            </Stack>
          </SimpleGrid>

          <Box>
            <HStack mr={10} mt={[10, 20]} mb={8}>
              <CountryHeading>Europe</CountryHeading>
              <EarthImage src={earthBlueLogo} alt="Planet Earth Blue Logo" />
            </HStack>
          </Box>

          <SimpleGrid columns={[1, 1, 3, 3, 3, 3]} pl={[14, 20, 14, 60]}>
            <Stack>
              <CountryLink>Armenia</CountryLink>
              <CountryLink>Azerbaijan</CountryLink>
              <CountryLink>Belarus</CountryLink>
              <CountryLink>België </CountryLink>
              <CountryLink>Belgique</CountryLink>
              <CountryLink>България</CountryLink>
              <CountryLink>Česko</CountryLink>
              <CountryLink>Danmark</CountryLink>
              <CountryLink>Deutschland</CountryLink>
              <CountryLink>Eesti</CountryLink>
              <CountryLink>España</CountryLink>
              <CountryLink>France</CountryLink>
              <CountryLink>Georgia</CountryLink>
              <CountryLink>Ελλάδα</CountryLink>
              <CountryLink>Hrvatska</CountryLink>
              <CountryLink>Ireland</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>Italia</CountryLink>
              <CountryLink>Kazakhstan</CountryLink>
              <CountryLink>Kyrgyzstan</CountryLink>
              <CountryLink>Latvija</CountryLink>
              <CountryLink>Liechtenstein</CountryLink>
              <CountryLink>Lietuva</CountryLink>
              <CountryLink>Luxembourg</CountryLink>
              <CountryLink>Magyarország</CountryLink>
              <CountryLink>Malta</CountryLink>
              <CountryLink>Moldova</CountryLink>
              <CountryLink>Montenegro</CountryLink>
              <CountryLink>Nederland</CountryLink>
              <CountryLink>North Macedonia</CountryLink>
              <CountryLink>Norge</CountryLink>
              <CountryLink>Österreich</CountryLink>
              <CountryLink>Polska</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>Portugal</CountryLink>
              <CountryLink>România</CountryLink>
              <CountryLink>Россия</CountryLink>
              <CountryLink>Slovensko</CountryLink>
              <CountryLink>Slovenia</CountryLink>
              <CountryLink>Schweiz</CountryLink>
              <CountryLink>Suisse</CountryLink>
              <CountryLink>Suomi</CountryLink>
              <CountryLink>Sverige</CountryLink>
              <CountryLink>Tajikistan</CountryLink>
              <CountryLink>Türkiye</CountryLink>
              <CountryLink>Turkmenistan</CountryLink>
              <CountryLink>United Kingdom</CountryLink>
              <CountryLink>Україна</CountryLink>
              <CountryLink>Uzbekistan</CountryLink>
            </Stack>
          </SimpleGrid>

          <Box>
            <HStack mr={10} mt={[10, 20]} mb={8}>
              <CountryHeading>Latin America and the Caribbean</CountryHeading>
              <EarthImage src={earthBlueLogo} alt="Planet Earth Blue Logo" />
            </HStack>
          </Box>

          <SimpleGrid columns={[1, 1, 3, 3, 3, 3]} pl={[14, 20, 14, 60]}>
            <Stack>
              <CountryLink>Anguilla</CountryLink>
              <CountryLink>Antigua & Barbuda</CountryLink>
              <CountryLink>Argentina</CountryLink>
              <CountryLink>Barbados</CountryLink>
              <CountryLink>Belize</CountryLink>
              <CountryLink>Bermuda</CountryLink>
              <CountryLink>Bolivia</CountryLink>
              <CountryLink>Brasil</CountryLink>
              <CountryLink>British Virgin Islands</CountryLink>
              <CountryLink>Cayman Islands</CountryLink>
              <CountryLink>Chile</CountryLink>
              <CountryLink>Colombia</CountryLink>
              <CountryLink>Costa Rica</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>Dominica</CountryLink>
              <CountryLink>República Dominicana</CountryLink>
              <CountryLink>Ecuador</CountryLink>
              <CountryLink>El Salvador</CountryLink>
              <CountryLink>Grenada</CountryLink>
              <CountryLink>Guatemala</CountryLink>
              <CountryLink>Guyana</CountryLink>
              <CountryLink>Honduras</CountryLink>
              <CountryLink>Jamaica</CountryLink>
              <CountryLink>México</CountryLink>
              <CountryLink>Montserrat</CountryLink>
              <CountryLink>Nicaragua</CountryLink>
              <CountryLink>Panamá</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>Paraguay</CountryLink>
              <CountryLink>Perú</CountryLink>
              <CountryLink>St. Kitts & Nevis</CountryLink>
              <CountryLink>St. Lucia</CountryLink>
              <CountryLink>St. Vincent & The Grenadines</CountryLink>
              <CountryLink>Suriname</CountryLink>
              <CountryLink>The Bahamas</CountryLink>
              <CountryLink>Trinidad & Tobago</CountryLink>
              <CountryLink>Turks & Caicos</CountryLink>
              <CountryLink>Uruguay</CountryLink>
              <CountryLink>Venezuela</CountryLink>
              <CountryLink>América Latina y el Caribe (Español)</CountryLink>
              <CountryLink>Latin America and the Caribbean (English)</CountryLink>
            </Stack>
          </SimpleGrid>

          <Box>
            <HStack mr={10} mt={[10, 20]} mb={8}>
              <CountryHeading>Middle-East and India</CountryHeading>
              <EarthImage src={earthBlueLogo} alt="Planet Earth Blue Logo" />
            </HStack>
          </Box>

          <SimpleGrid columns={[1, 1, 3, 3, 3, 3]} pl={[14, 20, 14, 60]}>
            <Stack>
              <CountryLink>Bahrain</CountryLink>
              <CountryLink>مصر</CountryLink>
              <CountryLink>البحرين</CountryLink>
              <CountryLink>India</CountryLink>
              <CountryLink>Israel</CountryLink>
              <CountryLink>Jordan</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>الأردن</CountryLink>
              <CountryLink>Kuwait</CountryLink>
              <CountryLink>الكويت</CountryLink>
              <CountryLink>Oman</CountryLink>
              <CountryLink>عُمان</CountryLink>
              <CountryLink>فلسطين</CountryLink>
            </Stack>
            <Stack>
              <CountryLink>Qatar</CountryLink>
              <CountryLink>قطر</CountryLink>
              <CountryLink>Saudi Arabia</CountryLink>
              <CountryLink> المملكة العربية السعودية</CountryLink>
              <CountryLink>United Arab Emirates</CountryLink>
              <CountryLink>الإمارات العربية المتحدة</CountryLink>
            </Stack>
          </SimpleGrid>

          <Box>
            <HStack mr={10} mt={10} mb={8}>
              <CountryHeading>North America</CountryHeading>
              <EarthImage src={earthBlueLogo} alt="Planet Earth Blue Logo" />
            </HStack>
          </Box>

          <SimpleGrid columns={[1, 1, 3, 3, 3, 3]} pl={[14, 20, 14, 60]} pb={20}>
            <Stack>
              <CountryLink>Canada (English)</CountryLink>
              <CountryLink>Canada (Français)</CountryLink>
              <CountryLink>Puerto Rico (English)</CountryLink>
              <CountryLink>Puerto Rico (Español)</CountryLink>
              <CountryLink>United States</CountryLink>
            </Stack>
          </SimpleGrid>
          <Box>
            <Image src={antarctica} alt="antarctica" h={[550, 700, 700, 960]} w="full" objectFit="cover" />
          </Box>
          <Box h={[550, 600, 600, 700]} w="full" bg="#FAFAFA" mt={3} mb={[16, 14, 3, 3]}>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Text
                maxW={"2xl"}
                px={[14, 24, 24, 10]}
                fontSize={[14, 20, 20, 26]}
                fontFamily="sequel-sans-semi-bold-head"
                color="#1C1D1F"
                pt={[20, 16]}
                pl={20}
              >
                It’s time for progress and greatness so at Parcks we think and we live according to what we are :
              </Text>

              <CustomHeading fontSize={[62, 85, 120, 160]} fontFamily="sequel-sans-semi-bold-head" pt={[6, 10, 12, 4]}>
                One people.
              </CustomHeading>

              <Text
                maxW={"2xl"}
                px={[14, 24, 20, 4]}
                fontSize={[12, 14, 16, 20]}
                fontFamily="sequel-sans-medium-body"
                color="#1C1D1F"
                pt={[10, 14]}
                pb={20}
                pl={20}
              >
                Parcks is encouraging and supporting the initiative of nonprofit Organisation IFOPE (International Flag
                of Planet Earth).
              </Text>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center" mt={-30}>
              <Link
                as={ReactLink}
                color="#3E7EFD"
                fontWeight={500}
                display="flex"
                alignItems="center"
                justifyContent="center"
                to="/discover-earth"
                fontFamily="sequel-sans-medium-head"
                fontSize={[12, 12, 14, 16]}
                _hover={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                pt={2}
                pb={20}
              >
                <HStack spacing="-1px">
                  <Text> Discover Flag of Planet Earth</Text>
                  <ChevronRightIcon boxSize={5} pos="relative" top={["1px"]} />
                </HStack>
              </Link>
            </Box>
          </Box>
        </>
      )}

      {planet === "MARS" && (
        <Box>
          <Image src={mars} alt="mars" h={[550, 700, 700, 960]} w="full" objectFit="cover" pb={[14, 14, 3, 3]} />
        </Box>
      )}
    </>
  );
};

export default Country;
