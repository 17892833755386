import "./Hamburger.css";
import React from "react";

const Hamburger = ({ handleMobileNavToggle, isOpened }) => {
  return (
    <div onClick={handleMobileNavToggle}>
      <div className="menu">
        <div>
          <span className="line-1" style={isOpened ? { transform: `translate(0,-44px) rotate(-45deg)` } : {}}></span>
          <span className="line-2" style={isOpened ? { transform: `translate(-50%,-50%) rotate(45deg)` } : {}}></span>
          <span className="line-3" style={isOpened ? { transform: `translate(-150%,40px) rotate(-45deg)` } : {}}></span>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
