import {
  Box,
  Link,
  Flex,
  Text,
  Input,
  VStack,
  HStack,
  Select,
  Spacer,
  Button,
  Spinner,
  Heading,
  Textarea,
  useToast,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import axios from "../../api/axios";
import { useState, useEffect } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as ReactLink } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import ParcksRectangle from "../../components/Rectangles/ParcksRectangle";
import { aboutParcks, countries, affiliationTypes } from "../../constants/constants";

const Parcks = () => {
  //capture user inputs
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [country, setCountry] = useState("");
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [affiliation, setAffiliation] = useState("");
  const [otherAffiliation, setOtherAffiliation] = useState("");

  //responsive sizes for the chevron right icon
  const iconSize = useBreakpointValue([16, 20]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //api call headers config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //form values / user inputs the user submitted
    const formValues = {
      name,
      email,
      country,
      affiliation_type: otherAffiliation ? otherAffiliation : affiliation,
      comments,
    };

    //send user inputs to the server/api
    try {
      const response = await axios.post("/api/comments/", JSON.stringify(formValues), config);

      //get the api response/status
      const status = response?.data?.status;

      setStatus(status);

      //clear current/old user inputs on a successful api call
      setName("");
      setEmail("");
      setCountry("");
      setOtherAffiliation("");
      setAffiliation("");
      setComments("");
      setErrMsg("");

      //send the form values to the designated parcks email address
      axios.post("https://formsubmit.co/ajax/14abd5616f7b5b9236267d11b978d797", formValues, config);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response.");
      } else {
        setErrMsg("We are unable to send your comments! Please check your network and try again.");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    //if our api call was successful and saved the comments, display a success toast else show an error toast
    if (status) {
      toast({
        title: "Comments Submitted",
        variant: "success",
        status: "success",
        description: "Your comments have been received. ",
        duration: 15000,
        position: "bottom-right",
        isClosable: true,
      });
    } else if (errMsg) {
      toast({
        title: "An Error Occurred",
        description: errMsg,
        status: "error",
        duration: 15000,
        position: "bottom-right",
        isClosable: true,
      });
    }
  }, [status, errMsg]);

  return (
    <>
      <BreadCrumb />
      <ParcksRectangle backgroundColor="#FAFAFA" boldCapitalText="STAND OUT." blueCapitalText="WATCH THE FILM" />
      <ParcksRectangle backgroundColor="#FFFFFF" blackSmallCaseText />
      <ParcksRectangle backgroundColor="#FAFAFA" boldBlackSmallCaseText />
      <Flex alignItems="center" justifyContent="center">
        <SimpleGrid columns={[1, 1, 2, 2, 2, 2]} pt={4} pb={20} spacing={2}>
          {aboutParcks.map((p, i) => {
            return (
              <Box key={i} w={[340, 510, 345, 470, 510, 500]} h={[420, 415, 400, 400]} bg="#292929" borderRadius={15}>
                <Heading fontFamily="sequel-sans-medium-head" color="white" pt={12} pl={[12, 20, 10, 20]}>
                  {p.title}
                </Heading>
                <Box h={[220, 204, 215, 204]} w={[340, 400, 340, 440]} px={[12, 0, 0, 0]}>
                  <Text
                    color="white"
                    pt={10}
                    pl={[-6, 20, 10, 20]}
                    fontFamily="sequel-sans-medium-head"
                    fontSize={[18, 18, 18, 16]}
                  >
                    {p.description}
                  </Text>
                </Box>

                <Box>
                  <Link
                    as={ReactLink}
                    color="#3E7EFD"
                    fontWeight={500}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    to={p.href}
                    fontFamily="sequel-sans-medium-head"
                    fontSize={[12, 16, 12, 14, 16]}
                    _hover={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    pt={[14, 10]}
                  >
                    <HStack spacing="-2px">
                      <Text>{p.linkText}</Text>
                      <ChevronRightIcon boxSize={5} pos="relative" top={["1.8px"]} />
                    </HStack>
                  </Link>
                </Box>
              </Box>
            );
          })}
        </SimpleGrid>
      </Flex>

      <Flex alignItems="center" justifyContent="center">
        <SimpleGrid columns={[1, 1, 2, 2]}>
          <Box pl={[14, 2, 8, 14, 60]} px={[14, 2, 6, 2, 20]}>
            <Heading
              pl={[2, 16, 16, 16]}
              fontFamily="sequel-sans-semi-bold-head"
              fontSize={[22, 24]}
              bgGradient="linear(to-l,#C83B82,#3290BC,#9A35BA,#D03F3F,#C83B82)"
              bgClip="text"
              display="none"
            >
              What’s on your mind ?
            </Heading>

            <Heading pl={[2, 16, 16, 16]} fontFamily="sequel-sans-semi-bold-head" fontSize={[22, 24]} pt={[0, 0, 14]}>
              We are the system.
            </Heading>
            <Heading pl={[2, 16, 16, 16]} fontFamily="sequel-sans-semi-bold-head" fontSize={[22, 24]}>
              By changing ourself,
            </Heading>
            <Heading pl={[2, 16, 16, 16]} pb={14} fontFamily="sequel-sans-semi-bold-head" fontSize={[22, 24]}>
              we change the system.
            </Heading>
          </Box>

          <Box pl={[0, 0, 4, 0, 24]}>
            <form onSubmit={handleSubmit}>
              <VStack pr={[0, 2, 14, 14]}>
                <Input
                  placeholder="Name"
                  w={80}
                  _placeholder={{ fontFamily: "sequel-sans-book-head", color: "#8D8D94" }}
                  isRequired
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <Spacer py={2} />
                <Input
                  placeholder="Email Address"
                  type="email"
                  w={80}
                  _placeholder={{ fontFamily: "sequel-sans-book-head", color: "#8D8D94" }}
                  isRequired
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <Spacer py={2} />
                <Select
                  placeholder="Select your Country or Region"
                  fontFamily="sequel-sans-book-head"
                  color="#8D8D94"
                  _placeholder={{ fontFamily: "sequel-sans-book-head" }}
                  w={80}
                  isRequired
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                >
                  {countries.map((c, ci) => {
                    return (
                      <option value={c.name} key={ci}>
                        {c.name}
                      </option>
                    );
                  })}
                </Select>
                <Spacer py={2} />
                <Select
                  fontFamily="sequel-sans-book-head"
                  color="#8D8D94"
                  placeholder="Select your Affiliation type"
                  _placeholder={{ fontFamily: "sequel-sans-book-head" }}
                  w={80}
                  isRequired
                  onChange={(e) => setAffiliation(e.target.value)}
                  value={affiliation}
                >
                  {affiliationTypes.map((at, ati) => {
                    return (
                      <option value={at.name} key={ati}>
                        {at.type}
                      </option>
                    );
                  })}
                </Select>

                {affiliation === "Other: Please specify" && (
                  <>
                    <Spacer py={2} />
                    <Input
                      placeholder="Specify your affiliation"
                      type="text"
                      w={80}
                      _placeholder={{ fontFamily: "sequel-sans-book-head", color: "#8D8D94" }}
                      onChange={(e) => setOtherAffiliation(e.target.value)}
                      value={otherAffiliation}
                      isRequired
                    />
                  </>
                )}
                <Spacer py={2} />
                <Textarea
                  placeholder="Comments"
                  _placeholder={{ fontFamily: "sequel-sans-book-head" }}
                  w={80}
                  h={160}
                  isRequired
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                />
                <Box pr={60} py={8}>
                  {!loading && (
                    <Button colorScheme="black" type="submit" variant="outline" borderRadius={50} ml={10} w={28}>
                      <Text fontFamily="sequel-sans-roman-body" fontSize={14}>
                        Submit
                      </Text>
                    </Button>
                  )}

                  {loading && (
                    <Button
                      colorScheme="black"
                      type="submit"
                      variant="outline"
                      borderRadius={50}
                      ml={10}
                      w={28}
                      isLoading
                    >
                      <Spinner thickness="4px" speed="0.25s" emptyColor="white" color="black" />
                    </Button>
                  )}
                </Box>
              </VStack>
            </form>
          </Box>
        </SimpleGrid>
      </Flex>

      <Box display="flex" alignItems="center" justifyContent="center" pb={16} pt={2}>
        <Link
          as={ReactLink}
          color="#3E7EFD"
          display="flex"
          alignItems="center"
          justifyContent="center"
          to="/sign-up"
          fontFamily="sequel-sans-medium-body"
          fontSize={15}
          _hover={{
            textDecoration: "none",
            cursor: "pointer",
          }}
          pt={10}
        >
          <HStack spacing="-2px">
            <Text>SIGN UP AND KEEP ME INFORMED</Text>
            <ChevronRightIcon boxSize={6} pos="relative" top={["-0px"]} />
          </HStack>
        </Link>
      </Box>
    </>
  );
};

export default Parcks;
